import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src170988513/src/solar-energy-partners/src/templates/page.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Container = makeShortcode("Container");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Container mdxType="Container">
      <h2>{`who we are`}</h2>
      <p>{`Our website address is: `}<a parentName="p" {...{
          "href": "https://solarenergy.partners"
        }}>{`https://solarenergy.partners`}</a>{`.`}</p>
      <h2>{`what personal data we collect and why we collect it`}</h2>
      <h3>{`comments`}</h3>
      <p>{`When visitors leave comments on the site we collect the data shown in the comments form, and also the visitor’s IP address and browser user agent string to help spam detection.`}</p>
      <p>{`An anonymized string created from your email address (also called a hash) may be provided to the Gravatar service to see if you are using it. The Gravatar service privacy policy is available here: `}<a parentName="p" {...{
          "href": "https://automattic.com/privacy/"
        }}>{`https://automattic.com/privacy/`}</a>{`. After approval of your comment, your profile picture is visible to the public in the context of your comment.`}</p>
      <h3>{`media`}</h3>
      <p>{`If you upload images to the website, you should avoid uploading images with embedded location data (EXIF GPS) included. Visitors to the website can download and extract any location data from images on the website.`}</p>
      <h3>{`cookies`}</h3>
      <p>{`If you leave a comment on our site you may opt-in to saving your name, email address and website in cookies. These are for your convenience so that you do not have to fill in your details again when you leave another comment. These cookies will last for one year.`}</p>
      <p>{`If you visit our login page, we will set a temporary cookie to determine if your browser accepts cookies. This cookie contains no personal data and is discarded when you close your browser.`}</p>
      <p>{`When you log in, we will also set up several cookies to save your login information and your screen display choices. Login cookies last for two days, and screen options cookies last for a year. If you select “Remember Me”, your login will persist for two weeks. If you log out of your account, the login cookies will be removed.`}</p>
      <p>{`If you edit or publish an article, an additional cookie will be saved in your browser. This cookie includes no personal data and simply indicates the post ID of the article you just edited. It expires after 1 day.`}</p>
      <h3>{`embedded content from other websites`}</h3>
      <p>{`Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website.`}</p>
      <p>{`These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded content if you have an account and are logged in to that website.`}</p>
      <h3>{`analytics`}</h3>
      <h2>{`how long we retain your data`}</h2>
      <p>{`If you leave a comment, the comment and its metadata are retained indefinitely. This is so we can recognize and approve any follow-up comments automatically instead of holding them in a moderation queue.`}</p>
      <p>{`For users that register on our website (if any), we also store the personal information they provide in their user profile. All users can see, edit, or delete their personal information at any time (except they cannot change their username). Website administrators can also see and edit that information.`}</p>
      <h2>{`what rights you have over your data`}</h2>
      <p>{`If you have an account on this site, or have left comments, you can request to receive an exported file of the personal data we hold about you, including any data you have provided to us. You can also request that we erase any personal data we hold about you. This does not include any data we are obliged to keep for administrative, legal, or security purposes.`}</p>
      <h2>{`where we send your data`}</h2>
      <p>{`Visitor comments may be checked through an automated spam detection service.`}</p>
      <h2>{`What Information Is Being Collected`}</h2>
      <p>{`Our website only collects the information that you submit through our forms which can include your name, email, phone number, average monthly electricity, gas, and heating bills, home address, and other comments you submit.`}</p>
      <h2>{`Who Is the Information Shared With?`}</h2>
      <p>{`The information you submit is only shared with the Solar Energy Partners team and is not sold to a third party.`}</p>
      <h2>{`How do we inform users of changes to this policy?`}</h2>
      <p>{`Updates can be found through dynamic banners on our website or by contacting our support team at `}<a parentName="p" {...{
          "href": "mailto:office@solarenergy.partners"
        }}>{`office@solarenergy.partners`}</a>{`.`}</p>
      <h2>{`Effective Date of Policy`}</h2>
      <p>{`This policy is in effect as of 10/25/2022.`}</p>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      